import React, { useState, useEffect } from 'react';
import { notification } from '../../component/hocs/notification';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';
import { mainurl } from '../../Mainurl';
import * as XLSX from 'xlsx/xlsx.mjs';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, InputGroup, InputGroupText, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FaEye, FaSearch, } from 'react-icons/fa';

const LivestockActivity = () => {
    const [mode, setMode] = useState("1");
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');

    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');

    const get_livestock_Activity = (e) => {
        e.preventDefault();

        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": sessionStorage.getItem('user_id'), "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Activity/liv_activity_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
        .then(function (response) {
            let list = response.data.map((row, index) => {
                return(
                    {
                       sl: index + 1,
                       farmer_id: row.farmer_id,
                       f_name: row.f_name,
                       activity_date: row.activity_date?.split(" ")[0].split('-').reverse().join('-'),
                       activity_desc: row.activity_desc,
                       medicine_name: row.medicine_name,
                       medicine_dose: row.medicine_dose,
                       animal_count: row.animal_count,
                    }
                )
            });

            setTableData(list);
        })
        .catch(error => {
            setLoading(false);
            //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
            //notify();
        });    

        
    }

    const download_excel_handler = () => {
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": sessionStorage.getItem('user_id'), "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Activity/liv_activity_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
        .then(function (response) {
            let list = response.data.map((row, index) => {
                return(
                    {
                       sl: index + 1,
                       farmer_id: row.farmer_id,
                       f_name: row.f_name,
                       activity_date: row.activity_date?.split(" ")[0].split('-').reverse().join('-'),
                       activity_desc: row.activity_desc,
                       medicine_name: row.medicine_name,
                       medicine_dose: row.medicine_dose,
                       animal_count: row.animal_count,
                    }
                )
            });

            if (list.length > 0) {
                let wb = XLSX.utils.book_new(),
                    ws = XLSX.utils.json_to_sheet(list);
                XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
                XLSX.writeFile(wb, "livestock_activity.xlsx");
                let notify = notification({ message: "Excel downloaded successfully", type: 'success' });
                notify();
            } else {
                let notify = notification({ message: "No data found!", type: 'error' });
                notify();
            }
        })
        .catch(error => {
            setLoading(false);
            //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
            //notify();
        }); 
    }

    return (
        <>
            <Header />
            <Page title='Livestock Activity' breadcrumbs='Home / Livestock Activity'>
                <Row className='p-3'>
                    {mode === "1" &&
                        <Col xs={12}>
                            <Card className='mb-3' color="primary" outline>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md={3} className="mb-2">
                                                <Button className='text-white' color='primary' size="sm" onClick={get_livestock_Activity} block> Get Livestock Activity </Button>
                                            </Col>
                                            <Col md={3} className="mb-2">
                                                <Button className='text-white' color='primary' size="sm" onClick={download_excel_handler} block> Download Livestock Activity Excel </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card className='' color="primary" outline>
                                <CardHeader>Livestock Activity <small className='text-muted'> </small></CardHeader>
                                <Col sm={{ offset: 6, size: 3 }} style={{ position: 'absolute', top: '2px', right: '2px' }}>
                                    <InputGroup size="sm">
                                        <InputGroupText> <FaSearch /> </InputGroupText>
                                        <Input type="search" placeholder='Search' />
                                    </InputGroup>
                                </Col>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: '#',
                                                selector: row => row.sl,
                                                sortable: true,
                                                width: '80px'
                                            },
                                            
                                            {
                                                name: 'Farmer ID',
                                                selector: row => row.farmer_id,
                                                sortable: false,
                                                width: '10%'

                                            },
                                            {
                                                name: 'Farmer Name',
                                                selector: row => row.f_name,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Activity Date',
                                                selector: row => row.activity_date,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Activity Description',
                                                selector: row => row.activity_desc,
                                                sortable: false,
                                                width: '20%'
                                            },
                                            {
                                                name: 'Medicine Name',
                                                selector: row => row.medicine_name,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Medicine Dose',
                                                selector: row => row.medicine_dose,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Animal Count',
                                                selector: row => row.animal_count,
                                                sortable: false,
                                            },
                                        ]}
                                        data={tableData}
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    }

                </Row>

            </Page>

            <Footer />

        </>
    )

}

export default LivestockActivity;