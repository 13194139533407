import React, { useState, useEffect } from 'react';
import Footer from './../component/layout/Footer';
import Header from '../component/layout/Header';
import Page from '../component/utility/Page';
import axios from 'axios';
import { mainurl } from '../Mainurl';

import KitchenGardenDashboard from '../component/utility/home/KitchenGardenDashboard';

import {Row, Col} from 'reactstrap';
import {GiFarmer, GiField, GiWheat, GiGoat, GiFruitBowl} from 'react-icons/gi';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HorticultureDashboard from '../component/utility/home/HorticultureDashboard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
//

const Home = () => {
    
    const [value, setValue] = useState(0);
    const [stats, setStats] = useState(0);

    useEffect(()=>{
        get_db_stats();
    }, []);

    const get_db_stats = () => {
        /*let postData = [{"org_id": sessionStorage.getItem('org_id')}];
        axios({
            method: 'post',
            url: mainurl + 'Master/web_dashboard_stats',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setStats(response.data[0]);
            })
            .catch(error => {
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });*/
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id":"0"}];
        axios({
            method: 'post',
            url: mainurl + 'Master/user_db_m',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setStats(response.data);
            })
            .catch(error => {
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Header />
            <Page title='' breadcrumbs=''>
                <Row className='p-3 mb-3'>
                    <Col md={3}>
                        <div className='dashboard_topbox'>
                            <Row>
                                <Col xs={8}>
                                    <div className="fs-16">Total Farmer</div>
                                    <div className='text-primary fs-25 mt-1'>{stats?.[0]?.key_value}</div>
                                </Col>
                                <Col xs={4} className="">
                                    <GiFarmer className='mt-2' size={55} color="#2e3192" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='dashboard_topbox'>
                            <Row>
                                <Col xs={8}>
                                    <div className="fs-16">Cultivation Area</div>
                                    <div className='text-primary fs-25 mt-1'>{stats?.[1]?.key_value}</div>
                                </Col>
                                <Col xs={4} className="">
                                    <GiField className='mt-2' size={55} color="#2e3192" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='dashboard_topbox'>
                            <Row>
                                <Col xs={8}>
                                    <div className="fs-16">Animal Count</div>
                                    <div className='text-primary fs-25 mt-1'>{stats?.[2]?.key_value}</div>
                                </Col>
                                <Col xs={4} className="">
                                    <GiGoat className='mt-2' size={55} color="#2e3192" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='dashboard_topbox'>
                            <Row>
                                <Col xs={8}>
                                    <div className="fs-16">Total Harvesting</div>
                                    <div className='text-primary fs-25 mt-1'>{stats?.[3]?.key_value} </div>
                                </Col>
                                <Col xs={4} className="">
                                    <GiWheat className='mt-2' size={55} color="#2e3192" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>


                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Kitchen Garden" {...a11yProps(0)} />
                        <Tab label="Vegetables" {...a11yProps(1)} />
                        <Tab label="Horticulture" {...a11yProps(2)} />
                        <Tab label="Aromatic" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <KitchenGardenDashboard />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div className='text-center mt-4'>No data available</div> 
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <HorticultureDashboard />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <div className='text-center mt-4'>No data available</div> 
                    </TabPanel>
                </Box>
                
            </Page>
            <Footer />
        </>
    )
}

export default Home;