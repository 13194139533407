//import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '../component/hocs/notification';
import { mainurl } from '../Mainurl';
import axios from 'axios';

import {
  Col, Row, Button, Form, FormGroup, Input, Label, UncontrolledCarousel, Spinner
} from 'reactstrap';

import slider_1 from '../assets/img/slider_1.png';
import slider_2 from '../assets/img/slider_2.png';
import slider_3 from '../assets/img/slider_3.png';
import slider_4 from '../assets/img/slider_4.png';



const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    let loginData = sessionStorage.getItem('loginData');
    if (loginData == null) {

    } else {
      navigate('/home');
    }
  }, []);

  const goto_register_page = () => {
    navigate('/register');
  }


  const login_handler = event => {
    event.preventDefault();
    setLoading(true);

    let postData = [{
      "org_id": "101",
      "login_id": userId,
      "password": password,
    }];
    axios({
      method: 'post',
      url: mainurl + 'Master/user_login',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {
        if (response.data.length > 0) {

          let loginData = JSON.stringify(response.data[0]);
          sessionStorage.setItem('loginData', loginData);
          sessionStorage.setItem('org_id', response.data[0].org_id);
          sessionStorage.setItem('user_id', response.data[0].user_code);

          let msg = "Welcome to Vegetable Box.";

          let notify = notification({ message: msg, type: 'success' });
          notify();

          navigate('/home');
          setLoading(false);

        } else {
          setLoading(false);
          let notify = notification({ message: "User id and password not matched! Try Again", type: 'error', time: true });
          notify();
        }


      }).catch(error => {
        setLoading(false);
        let notify = notification({ message: "User id and password not matched! Try Again", type: 'error', time: true });
        notify();
      })
  };

  return (
    <Row className="d-flex justify-content-center align-items-center br-5"
      style={{
        background: '#2e3192',
        height: '100vh',
        marginTop: "-70px"
      }}>

      <Col md={10} lg={10}>
        <Row style={{ backgroundColor: '#FFF' }}>
          <Col className=" p-0 d-none d-md-block" md={6} lg={8}>
            <UncontrolledCarousel
              items={[
                {
                  altText: '',
                  caption: '',
                  key: 1,
                  src: slider_1
                },
                {
                  altText: '',
                  caption: '',
                  key: 2,
                  src: slider_2
                },
                {
                  altText: '',
                  caption: '',
                  key: 3,
                  src: slider_3
                },
                {
                  altText: '',
                  caption: '',
                  key: 4,
                  src: slider_4
                },
              ]}
            />
          </Col>
          <Col className="p-4" md={6} lg={4} >

            <div className="text-center text-primary" style={{ fontSize: '30px', marginTop: '50px' }}>
              <img src={require('../assets/img/logo1.png')} alt="l.." height="100px" />
            </div>
            <div className="text-muted text-center"><b>Agriculture Management System</b></div>

            <Form className="mt-3 mb-3" onSubmit={login_handler}>
              <div className="text-center pb-2">
                <h5><b>Login</b></h5>
              </div>
              <FormGroup>
                <Input bsSize="md" type="text" name='userName' placeholder='User name' onChange={(e) => setUserId(e.target.value)} required />
              </FormGroup>
              <FormGroup>
                <Input bsSize="md" type="password" name='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} required />
              </FormGroup>

              <Button
                size="md"
                color="primary"
                className='text-white'
                block
                disabled={loading}
                type="submit">Login <span>{loading && <Spinner color="secondary" size="sm" >Load...</Spinner>}</span>
              </Button>
            </Form>

            <small className="d-block mb-4 text-center">
              {/* <Button
                size="sm"
                color="secondary"
                onClick={goto_register_page}
              >Register New Organization
              </Button> */}
            </small>
            <small className="d-block mb-1 text-muted text-center">Developed by Vegbox</small>

          </Col>

        </Row>
      </Col>


    </Row>
  );
}

export default Login;
